<template>
  <v-select
    :items="colors"
    label="Farbe"
    :readonly="readonly"
    :value="value"
    @input="input"
  >
    <template v-slot:item="{ item }">
      <v-icon :color="item">mdi-checkbox-blank-circle</v-icon>
    </template>
    <template v-slot:selection="{ item }">
      <v-icon v-if="readonly" color="grey lighten-3"
        >mdi-checkbox-blank-circle</v-icon
      >
      <v-icon v-else :color="item">mdi-checkbox-blank-circle</v-icon>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "ColorInput",
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    value: String,
  },
  data() {
    return {
      colors: [
        "#82f09b", // Light Green
        "#048a81", // Celadon Green
        "#54c6eb", // Vivid Sky Blue
        "#8a89c0", // Blue Bell
        "#cda2ab", // Tuscany
        "#bf4e30", // Vermilion
      ],
    };
  },
  methods: {
    input(event) {
      this.$emit("input", event);
    },
  },
};
</script>

<style scoped>
.v-input {
  margin: 0;
}
</style>
